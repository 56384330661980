.rich-text {
    &.is-invalid {
        .fr-toolbar, .fr-second-toolbar {
            border-color: #dc3545;
        }
        .fr-wrapper {
            border-left-color: #dc3545;
            border-right-color: #dc3545;
        }
    }

    .fr-element, .fr-box.fr-basic .fr-element {
        font-family: Arial,Helvetica,sans-serif;
        font-size: 12pt;
    }

    .fr-element table td, .fr-element table th {
        border: 1px solid #ccc;
        padding: 1px 2px;
    }
}
