.index-table {
    .doc-nbr {
        width: 60px;
    }
    .doc-lastchange {
        width: 120px;
    }
    .doc-status {
        width: 120px;
    }
}
