.assignee-note {
    border-top: 1px solid #ccc;
    margin-top: 6px;
    padding-top: 6px;

    .assignee-note-text {
        white-space: pre-wrap;
        overflow: hidden;
        max-height: 60px;
        position: relative;

        .assignee-note-fade {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px;
            background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1));
        }
    }

    &.expanded {
        .assignee-note-text {
            max-height: none;
            .assignee-note-fade {
                display: none;
            }
        }

        .assignee-note-btn {
            display: none;
        }
    }
}
