.autocomplete {
    $self: &;

    position: relative;

    &__options {
        position: absolute;
        display: none;
        background: white;
        width: 100%;
        border: 1px solid #86b7fe;
        border-top: none;
        border-radius: 0 0 4px 4px;
        z-index: 1000;
    }

    &--focus {
        #{$self}__options {
            display: block;
        }
    }

    &__option {
        $opt: &;

        padding: 4px 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &__info {
            color: #aaa;
            padding-left: 12px;
        }

        &:not(.autocomplete__option--disabled) {
            &:hover {
                background: #eee;
            }
            &#{$opt}--selected {
                background: #ccc;
            }
        }

        &--disabled {
            color: #aaa;
            font-style: italic;
        }
    }
}
