span.attention {
    color: #C0504D;
}

.fr-toolbar .fr-btn-grp {
  padding: 0 6px;
  margin: 0;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #ddd;
}

.fr-view ul {
  line-height: 1.8em;
}
