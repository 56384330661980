.users-col-head {
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
    border: 0;
    background: none;
    padding: 0;
    width: 100%;
    &:hover {
        text-decoration: underline;
    }
    > span {
        flex-grow: 1;
        text-align: left;
    }
    > svg {
        flex-grow: 0;
    }
}

.users-tbl {
    th {
        min-width: 150px;
    }
}
