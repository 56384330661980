.app-layout {
    padding-top: 57px;

    min-height: 100%;
    margin-bottom: -61px;
    padding-bottom: 77px;

    .container.main {
        padding-top: 16px;
    }

    &--status-bar {
        margin-bottom: -86px;
        padding-bottom: 102px;
    }
}
