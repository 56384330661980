@font-face {
    font-family: 'CarlitoBold';
    src: url('./Carlito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'CarlitoBoldItalic';
    src: url('./Carlito-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'CarlitoItalic';
    src: url('./Carlito-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'CarlitoRegular';
    src: url('./Carlito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* The following rules are deprecated. */

@font-face {
    font-family: 'Carlito';
    src: url('./Carlito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}



@font-face {
    font-family: 'Carlito';
    src: url('./Carlito-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}



@font-face {
    font-family: 'Carlito';
    src: url('./Carlito-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}



@font-face {
    font-family: 'Carlito';
    src: url('./Carlito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
