.user-info-section {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .contact-icon {
        width: 20px;
        margin-right: 6px;
    }

}

.user-email {
    border-bottom: 1px dashed #0d6efd;
    text-decoration: none;

    &.user-disabled {
        color: #dd0001;
    }

    &:hover {
        text-decoration: none;
    }
}
