.indicator {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ddd;
    vertical-align: middle;

    &.PENDING_REVIEW {
      background-color: #f0ad4e;
    }
    &.IN_PROGRESS {
        background-color: #f0ad4e;
    }
    &.PENDING_APPROVAL {
        background-color: #f0ad4e;
    }
    &.APPROVED {
        background-color: #5bc0de;
    }
    &.READY_FOR_PRINT {
        background-color: #93c;
    }
    &.WITH_DTP_COORDINATOR{
        background-color: #00008B;
    }
    &.WITH_MINISTER {
        background-color: #5cb85c;
    }
    &.REQUIRES_UPDATE {
        background-color: #f0ad4e;
    }
    &.CULLED {
        background-color: #d9534f;
    }
}
