.loading-overlay {
    display: none;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    justify-content: center;
    align-items: center;
    opacity: 0;

    &--display {
        display: flex;
    }
    &--transition {
        opacity: 1;
        transition: opacity 0.2s ease-in 0.2s;
    }

    &__content {
        border: 2px solid #c0b2ff;
        background: white;
        border-radius: 6px;
        padding: 16px;
        text-align: center;
        min-width: 200px;
    }
}
