.print-portfolios {

    .portfolio-option {
        display: flex;
        gap: 4px;
        .portfolio-name {
            flex-grow: 1;
            white-space: pre-wrap;
        }
    }

    .print-portfolios-index {
        .print-portfolios-title {
            font-size: 14pt;
            text-align: center;
            font-weight: bold;
            margin-bottom: 12px;
        }
        .topic-group {
            margin: 32px 0 18px 0;
        }
        .topic {
            font-weight: bold;
        }
        .topic-number, .topic-doc-number {
            display: inline-block;
            padding-right: 4px;
            min-width: 48px;
        }
    }
}
