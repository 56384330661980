.view-document {

    .page-container {
        max-width: 720px;
    }

    .head-section {
        background-color: #fcf8e3;
        border-bottom: 1px solid #faebcc;
        margin-bottom: 20px;
        color: #8a6d3b;
    }

    .download-btns {
        display: flex;
        gap: 8px;

        padding: 10px 0;
    }

    .page {
        font-family: 'Arial';
        font-size: 14pt;

        h2 {
            font-weight: bold;
            font-size: 14pt;
            margin-top: 0px;
            text-transform: uppercase;
        }

        .editionDate {
            background-color: #ccc;
            font-size: 12px;
            padding: 5px 10px;
            margin-bottom: 10px;
            display: inline-block;
        }

        .portfolio {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .number {
            font-weight: bold;
            margin-bottom: 5px;
            text-align: right;
        }

        .title {
            font-weight: bold;
            padding: 0 0 3px 0;
        }

        .section {
            margin-top: 24px;
            font-size: 12pt;
        }

        .user-info {
            font-style: italic;
            margin-top: 30px;
        }

        .revision-info {
            font-size: 14px;
            color: #666;
            padding: 24px 0;
        }

        table {
            font-size: 9pt;
            border-collapse: collapse;
            td, th {
                border: 1px solid black;
                padding: 3px;
            }
            th {
                font-weight: bold;
            }
            .third {
                width: 33%;
            }
        }

        span.attention {
            color: #C0504D;
        }

        .details-table {
            margin: 24px 0;
            width: 100%;
            border-collapse: collapse;
            th, td {
                width: 33%;
                vertical-align: top;
            }
        }

        &.ppq {
            .portfolio {
                text-align: right;
                .portfolio-label {
                    display: none;
                }
            }
            .number {
                display: none;
            }
            .title {
                margin: 20px 0;
            }
        }

        &.paec {
            .title {
                margin: 20px 0 8px;
            }
        }
    }

    .index-header {
        background-color: #000;
        color: #fff;
        padding: 5px;
        text-align: center;
        > h2 {
            margin: 0px 0px 4px 0;
            text-transform: none;
        }
    }

    .page-break {
        border-bottom: 1px dashed #ddd;
        position: relative;
        margin: 0px 0;
        width: 100%;
        padding: 0px;

        span {
            position: absolute;
            background-color: white;
            font-size: 12px;
            color: #ddd;
            left: 50%;
            top: -6px;
            padding: 0px 5px;
            margin-left: -40px;
        }
    }


}
