@mixin reset-button-styling {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.select-panel {
    overflow: auto;
    max-height: 70vh;
    margin-bottom: 20px;
    .isActive {
        button {
            color: var(--bs-white);
        }
        background-color: var(--bs-primary);
    }
    &__link {
        @include reset-button-styling;
        display: flex;
        gap: 8px;
        width: 100%;
        justify-content: space-between;
        text-align: left;
        align-items: center;
        text-decoration: none;
        color: #337ab7;
        &:hover {
            color: #23527c;
        }
    }
    &__nbr {
        font-weight: bold;
        display: none;
    }
    &__name {
        flex-grow: 1;
    }
    &--shownbr {
        .select-panel__nbr {
            display: block;
        }
    }
}
