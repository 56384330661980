.checkbox label .toggle,
.checkbox-inline .toggle {
    margin-left: -20px;
    margin-right: 5px;
}
.toggle {
    position: relative;
    overflow: hidden;
    &:focus-within {
        box-shadow: var(--focus-boxshadow);
    }
}
.toggle input[type="checkbox"] {
    position: absolute;
}

.toggle-group {
    position: absolute;
    width: 200%;
    top: 0;
    bottom: 0;
    left: 0;
    transition: left 0.35s;
}
.toggle.off .toggle-group {
    left: -100%;
}
.toggle.invalid {
    border: 1px solid red;
}

.toggle-on {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    margin: 0;
    border: 0;
    border-radius: 0;
    background-color: var(--bs-primary);
    color: var(--bs-white) !important;
    &:hover {
        background-color: var(--bs-primary-dark);
    }
}
.toggle-off {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    background-color: var(--bs-gray-200);
    color: var(--bs-body-color);
    &:hover {
        background-color: var(--bs-gray-400);
    }
}
.toggle-handle {
    position: relative;
    background-color: var(--bs-white);
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
    width: 0;
    border-width: 0 1px;
    pointer-events: none;
}
.toggle.btn {
    min-width: 59px;
    min-height: 38px;
}
.toggle-on.btn {
    padding-right: 24px;
}
.toggle-off.btn {
    padding-left: 24px;
}
