.topics-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    &__inner {
        border: 1px solid var(--bs-gray-200);
        margin-bottom: 20px;
    }
    &__heading {
        padding: 10px;
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;
        > span {
            &:first-child {
                width: 80px;
            }
            &:nth-child(2) {
                flex-grow: 1;
            }
        }
    }
    &__item {
        padding: 10px;
        &:not(:last-child) {
            border-bottom: 1px solid var(--bs-gray-200);
        }
    }
    &__inputs {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;
        & > * {
            margin-bottom: 0;
        }
        & > :first-child {
            width: 80px;
        }
        & > :nth-child(2) {
            flex: 1;
        }
    }
    &__err {
        color: #dc3545;
        font-size: 14px;
    }
}
