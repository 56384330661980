.history-item {
    padding: 4px 0;
    &:not(:first-child) {
        border-top: 1px solid #ccc;
    }

    .history-info {
        display: flex;
        gap: 6px;
        align-items: center;

        .user-name {
            flex-grow: 1;
            &.user-disabled {
                color: #dd0001;
            }
        }
    }

    .history-comment {
        padding-top: 4px;
        padding-left: 22px;
        color: #666;
        white-space: pre-wrap;
    }
}
