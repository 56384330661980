.status-item {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    margin-bottom: 6px;
    > label {
        flex-grow: 0;
        flex-shrink: 0;
        width: 100px;
        font-weight: bold;
    }
    > span {
        flex-grow: 1;
    }
}
